
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import ChipHackmetrix from '@/components/Chip/Index.vue'
// import { FrameworkInterface, } from '@/store/types/Polimorfic/Framework'

@Component({
  components: {
    ChipHackmetrix,
  },
  computed: {
    ...mapGetters('BusinessModule', ['getSelectedProduct']),
    ...mapGetters('PolimorficModule', ['getFramework']),
  },
})
export default class FileBusinessComponent extends Vue {
  private Business = this.$store.state.BusinessModule.BusinessState.businessSelected;
  private Businessid = this.$route.params.id === undefined ? this.$store.state.BusinessModule.BusinessState.businessSelected.id : this.$route.params.id;

  private divishLvlDoc = "";
  //private divishLArq = ''
  private architectures: string[] = [];
  private frameworksNames: any[] = [];
  private names = "";
  private minuta = this.$store.state.BusinessModule.BusinessState.businessSelected
    .kickoff_url;
  private minutaName = "";
  private minutaUrl = "";
  private saq = false;

  mounted(): void {
    this.updateBusinessDetails()
    this.minuta ? (this.minutaName = this.minuta.file_name) : this.minutaName
    this.minuta ? (this.minutaUrl = this.minuta.url) : this.minutaUrl
    this.divishLvlDoc = this.Business.documentation_level
    this.pciSaqSelected()
  }

  private updateBusinessDetails() {
    this.$store.dispatch('BusinessModule/getBusinessDetail', this.Businessid).then((e: any) => {
      this.Business = e.data.company
      this.$store.commit('BusinessModule/SET_BUSINESS_SELECTED', e.data.company)
    })
  }

  private pciSaqSelected() {
    const item: any = this.Business.frameworks.find((item: any) => item.id === 3)
    if (item) {
      this.saq = true;
    } else {
      this.saq = false;
    }
  }
  beforeDestroy() {
    this.$store.commit("BusinessModule/SET_BUSINESS_SELECTED", {});
  }
}
